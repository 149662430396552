import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import BlogCard from '../components/BlogContent/BlogCard'
import { useIntl } from "gatsby-plugin-intl"

const Blog = () => {
    const intl = useIntl()
    let homePageUrl = '/en/'
    if (intl.locale == 'vi') {
        homePageUrl = '/vi/'
    }
    return (
        <Layout>
            <NavbarTwo bgWhite={false}/>
            <PageBanner
                pageTitle={intl.formatMessage({ id: "blogs" })}
                homePageText={intl.formatMessage({ id: "home" })}
                homePageUrl={homePageUrl}
                activePageText={intl.formatMessage({ id: "blogs" })}
            />
            <BlogCard />
            <Footer />
        </Layout>
    );
}

export default Blog;