import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import Image from 'gatsby-image'
import { useIntl } from "gatsby-plugin-intl"

const BlogCard = () => {
  const intl = useIntl()
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {frontmatter: {type: {eq: "blog"}}}
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 10
        ) {
          edges {
            node {
              id
              frontmatter {
                type
                language
                title
                slug
                date
                cover {
                  childImageSharp {
                      fluid(maxWidth: 750, maxHeight: 650, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                  }
                }
                tags
                author
                aavatar {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const posts = allMarkdownRemark.edges
  let dateFormattedLocale = 'en-US'
  if (intl.locale == 'vi') {
    dateFormattedLocale = 'vi-VN'
  }
  return (
    <div className="blog-area ptb-100">
      <div className="container">
        <div className="row">
          {posts.map(post => {
            const date = new Date(post.node.frontmatter.date);
            const dateFormatted = new Intl.DateTimeFormat(
              dateFormattedLocale, { year: 'numeric', month: 'long', day: '2-digit' }
            ).format(date)

            return (
              <div key={post.id} className="col-lg-4 col-md-6">
                <div className="single-blog-post bg-fffbf5">
                  <div className="post-image">
                    <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`}>
                      <Image fluid={post.node.frontmatter.cover.childImageSharp.fluid} alt="thumbnail" />
                    </Link>
                  </div>

                  <div className="post-content">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                      <li>
                        <div className="post-author d-flex align-items-center">
                          <img src={post.node.frontmatter.aavatar.publicURL} className="rounded-circle" alt="image" />
                          <span>{post.node.frontmatter.author}</span>
                        </div>
                      </li>
                      <li>
                        <i className='flaticon-calendar'></i> {dateFormatted}
                      </li>
                    </ul>
                    <h3>
                      <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`}>
                        {post.node.frontmatter.title}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            )
          })}

          <div className="col-lg-12 col-md-12">
            {/* Pagination */}
            <div className="pagination-area text-center">
              <Link to="#" className="prev page-numbers">
                <i className='bx bx-chevrons-left'></i>
              </Link>
              <span className="page-numbers current" aria-current="page">1</span>
              <Link to="#" className="page-numbers">
                2
                            </Link>
              <Link to="#" className="page-numbers">
                3
                            </Link>
              <Link to="#" className="page-numbers">
                4
                            </Link>
              <Link to="#" className="next page-numbers">
                <i className='bx bx-chevrons-right'></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard